import React, { useRef, useEffect } from 'react';
import './HomePage.css';
import logoBicho from './logo_bichoderua.svg';

const HomePage = () => {
  const eyeRef = useRef(null);

  useEffect(() => {
    if (eyeRef.current) {
      eyeRef.current.classList.add('eye');
    }
  }, []);

  return (
    <div className="homepage">
      <div className="container">
        <img src={logoBicho} alt="Logo Bicho de Rua, um gatinho e um cachorrinho sentados lado a lado ao luar" className="logo" />
        <svg>
          <g ref={eyeRef}>
            <use xlinkHref="#eyeG" />
          </g>
        </svg>

        <h1 className="title">Nosso novo site está a caminho!</h1>
        <p className="message">
          Estamos construindo um espaço ainda mais especial para você e seus
          amigos de quatro patas. Acompanhe nossas redes sociais para ficar por
          dentro das novidades e seja um dos primeiros a conhecer o novo Bicho
          de Rua! ✨
        </p>
        <div className="social-links">
          <a
            href="https://www.facebook.com/paginadobicho"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/bichoderua"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <p className="old-site-link">
          Enquanto isso, visite nosso{' '}
          <a
            href="https://www.bichoderua.org.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            site antigo
          </a>
          .
        </p>
        <div className="loading-indicator"></div>
      </div>
    </div>
  );
};

export default HomePage;
